<template>
  <div style="width: 100%">
    <h5 class="text-danger" v-if="listPermission.length === 0">It is necessary to first have roles created</h5>
    <validation-observer v-slot="{handleSubmit}">
      <form @submit.prevent="handleSubmit(createUser)">

        <b-form-group id="input-group-1" label="User role" label-for="input_name" description="">
          <b-form-select v-model="selectedRol" :options="arrayRoles" class="">
            <b-form-select-option :value="null">Select an option</b-form-select-option>
          </b-form-select>
        </b-form-group>


        <ValidationProvider name="Name" rules="required">
          <div slot-scope="{ errors }">
            <label for="input_name">Name</label>
            <input
              class="form-control"
              id="input_name"
              v-model="user.name"
              type="text"
              placeholder="Enter name"
              required
            >
            <p v-if="errors[0]" class="error_field">{{ $t(`vue_validator.${errors[0]}`) }}</p>
          </div>
        </ValidationProvider>


        <ValidationProvider name="email" rules="required|email">
          <div slot-scope="{ errors }">
            <label for="input_email">Email Login</label>
            <input
              id="input_email"
              v-model="user.email"
              class="form-control"
              type="email"
              placeholder="Enter email"
              required
            >
            <p v-if="errors[0]" class="error_field">{{ $t(`vue_validator.${errors[0]}`) }}</p>
          </div>
        </ValidationProvider>

        <ValidationProvider name="password" rules="required|min:4">
          <div slot-scope="{ errors }">
            <label for="input_password">Password</label>
            <input
              id="input_password"
              v-model="user.password"
              class="form-control"
              type="text"
              placeholder="Enter password"
              required
            >
            <p v-if="errors[0]" class="error_field">{{ $t(`${errors[0]}`) }}</p>
          </div>
        </ValidationProvider>


        <div v-if="listPermission.length>0 && $checkSubUserPermission('user_management.create-user')" class="mt-3"
             style="display: flex;justify-content: space-around;">
          <b-button type="submit" variant="primary">Submit</b-button>
          <b-button type="reset" @click="clearAll" variant="danger">RESET</b-button>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import {mapState} from "vuex";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: "CreateUser",
  mixins: [show_alert_mixin],
  props: {
    arrayRoles: {
      default: []
    }
  },
  data: () => ({
    selectedRol: null,
    user: {
      name: null,
      email: null,
      password: null
    },

  }),

  computed: {
    ...mapState('subUserPermission', ['listPermission'])
  },
  methods: {
    createUser() {
      if (this.listPermission.length > 0) {
        if (this.selectedRol !== null && this.user.name !== null && this.user.email !== null && this.password !== null) {
          this.user.email = this.user.email.toLowerCase()
          this.$store.dispatch("subUserPermission/httpCreateSubUser", {
            parent: this.$store.getters.getUserId,
            rol: this.selectedRol,
            ...this.user
          }).then(data => {
            if (data == 200) {
              this.clearAll()
              this.$store.dispatch("subUserPermission/httpGetListAccounts")
              this.showAlertNotification("User created")
            } else {
              this.showAlertNotification("This email is already registered in our database.", "error")
            }
          })
        } else {
          this.showAlertNotification("All fields are required", "error")
        }
      } else {
        this.showAlertNotification("All roles must be created and have permissions assigned", "error")
      }
    },

    clearAll() {
      this.selectedRol = null
      this.user = {
        name: null,
        email: null,
        password: null
      }
    }
  }
}
</script>

<style scoped>

</style>
