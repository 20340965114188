var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[(_vm.listPermission.length === 0)?_c('h5',{staticClass:"text-danger"},[_vm._v("It is necessary to first have roles created")]):_vm._e(),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.createUser)}}},[_c('b-form-group',{attrs:{"id":"input-group-1","label":"User role","label-for":"input_name","description":""}},[_c('b-form-select',{attrs:{"options":_vm.arrayRoles},model:{value:(_vm.selectedRol),callback:function ($$v) {_vm.selectedRol=$$v},expression:"selectedRol"}},[_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("Select an option")])],1)],1),_c('ValidationProvider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('div',{},[_c('label',{attrs:{"for":"input_name"}},[_vm._v("Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.name),expression:"user.name"}],staticClass:"form-control",attrs:{"id":"input_name","type":"text","placeholder":"Enter name","required":""},domProps:{"value":(_vm.user.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "name", $event.target.value)}}}),(errors[0])?_c('p',{staticClass:"error_field"},[_vm._v(_vm._s(_vm.$t(("vue_validator." + (errors[0])))))]):_vm._e()])}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('div',{},[_c('label',{attrs:{"for":"input_email"}},[_vm._v("Email Login")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.email),expression:"user.email"}],staticClass:"form-control",attrs:{"id":"input_email","type":"email","placeholder":"Enter email","required":""},domProps:{"value":(_vm.user.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "email", $event.target.value)}}}),(errors[0])?_c('p',{staticClass:"error_field"},[_vm._v(_vm._s(_vm.$t(("vue_validator." + (errors[0])))))]):_vm._e()])}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('div',{},[_c('label',{attrs:{"for":"input_password"}},[_vm._v("Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.password),expression:"user.password"}],staticClass:"form-control",attrs:{"id":"input_password","type":"text","placeholder":"Enter password","required":""},domProps:{"value":(_vm.user.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "password", $event.target.value)}}}),(errors[0])?_c('p',{staticClass:"error_field"},[_vm._v(_vm._s(_vm.$t(("" + (errors[0])))))]):_vm._e()])}}],null,true)}),(_vm.listPermission.length>0 && _vm.$checkSubUserPermission('user_management.create-user'))?_c('div',{staticClass:"mt-3",staticStyle:{"display":"flex","justify-content":"space-around"}},[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v("Submit")]),_c('b-button',{attrs:{"type":"reset","variant":"danger"},on:{"click":_vm.clearAll}},[_vm._v("RESET")])],1):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }