<template>
  <div class="table-responsive">
    <b-table small bordered hover :items="listAccounts" :fields="fields">
      <template #cell(rol)="row">
        {{ $store.getters["subUserPermission/getTranslateRoles"]((row.item.rol.rol)) }}
      </template>
      <template #cell(actions)="row">
        <b-button v-if="$checkSubUserPermission('user_management.delete-user')" size="sm" class="btn-primary"
                  @click="deleteUser(row.item._id)">
          <i class="material-icons">delete</i>
        </b-button>
        <b-button size="sm" class="btn-primary"
                  v-if="$checkSubUserPermission('user_management.update-password')"
                  v-b-modal.modal-1 @click="setUserId(row.item._id)">
          <i class="material-icons">key</i>
        </b-button>
      </template>
    </b-table>
    <b-modal id="modal-1" title="Update password" hide-footer>
      <validation-observer v-slot="{handleSubmit}">
        <form @submit.prevent="handleSubmit(updatePassword)">
          <ValidationProvider name="password" rules="required|min:4">
            <div slot-scope="{ errors }">
              <label for="input_password">Password</label>
              <input
                id="input_password"
                v-model="newPassword"
                class="form-control"
                type="text"
                placeholder="Enter new password"
                required
              >
              <p v-if="errors[0]" class="error_field">{{ $t(`${errors[0]}`) }}</p>
            </div>
          </ValidationProvider>
          <b-button class="mt-3" variant="primary" type="submit" block>UPDATE PASSWORD</b-button>
        </form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {post, put} from "@/services/api";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: "ListUsers",
  mixins: [show_alert_mixin],
  props: {
    userId: {
      default: null
    }
  },
  data: () => ({
    fields: ["name", "email", {
      key: 'rol',
      label: 'Role'
    }, "actions"],

    userSelected: null,
    newPassword: null
  }),
  computed: {
    ...mapState("subUserPermission", ["listAccounts"])
  },
  methods: {
    deleteUser(id) {
      this.$swal({
        html: "Are you sure you want to delete this user?",
        showCancelButton: true,
        confirmButtonColor: '#d14343',
        cancelButtonColor: '#000000',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("subUserPermission/httpDeleteUserAccount", id).then(() => {
            this.$store.dispatch("subUserPermission/httpGetListAccounts", this.userId)
          })
        }
      });
    },
    setUserId(id) {
      this.userSelected = id
    },
    updatePassword() {
      if (this.userSelected !== null && this.newPassword !== null) {
        post("user-sub-permissions/update-password", {_id: this.userSelected, password: this.newPassword}).then(() => {
          this.userSelected = null
          this.newPassword = null
          this.$root.$emit('bv::hide::modal', 'modal-1')
          this.showAlertNotification("Password updated")
        })
      }
    },
  }
}
</script>

<style scoped>

</style>
