<template>
  <b-table hover bordered small :items="items" :fields="fields">
    <template #cell(actions)="row">
      <b-button size="sm" class="btn-primary" @click="view(row.item)">
        <i class="material-icons">visibility</i>
      </b-button>

    </template>

    <template #cell(rol)="row">
      {{ $store.getters["subUserPermission/getTranslateRoles"]((row.item.rol)) }}
    </template>

  </b-table>
</template>

<script>
export default {
  name: "ListPermission",
  props: {
    items: {
      default: []
    },
  },
  data: () => ({
    fields: [{
      key: 'rol',
      label: 'Role'
    }, "actions"]
  }),
  methods: {
    view(item) {
      this.$store.dispatch("subUserPermission/findOneListPermissions", item._id)
    },

  }
}
</script>

<style scoped>

</style>
