import Vue from 'vue';
import CreatePermission from './CreatePermission'
import CreateUser from './CreateUser'
import ListPermission from './ListPermission'
import ListUsers from './ListUsers'
import showPermission from './showPermission'
import {mapState} from "vuex";

export default Vue.extend({
  name: 'SubUser',
  components: {
    CreatePermission,
    CreateUser,
    ListPermission,
    ListUsers,
    showPermission,
  },
  data: () => ({
    userId: null,
    arrayRoles: []
  }),
  mounted() {
    if (this.$store.getters.getRol !== 'administrator') {
      this.userId = this.$store.getters.getUserId
      this.$store.commit("subUserPermission/setPermissionSelected", null)
      this.$store.dispatch("subUserPermission/httpGetListAccounts", this.userId)
      this.fillCombo()
    }
  },
  computed: {
    ...mapState('subUserPermission', ['listPermission'])
  },
  methods: {
    fillCombo() {
      this.arrayRoles = []
      this.listPermission.map(item => {
        const label = this.$store.getters["subUserPermission/getTranslateRoles"](item.rol)
        this.arrayRoles.push({value: item.rol, text: label})
      })
    },
  },
  watch: {
    listPermission() {
      this.fillCombo()
    }
  }
});
