<template>
  <div style="width: 100%">
    <b-row>
      <b-col>
        <b-form-group id="input-group-1" label="Name" label-for="input_name" description="">
          <b-form-select v-model="selectedRol" :options="arrayRoles" class="mb-3"></b-form-select>
        </b-form-group>
        <div class="tree-box box-border">
          <ul class="trees">
            <li class="has-child" v-for="(item) in MODULES_PLATFORM">
              <input id="tree-control1" type="checkbox" checked><span
              class="tree-control"></span>
              <label v-if="item.label.toString() !=='Dashboard'">
                <input type="checkbox" v-model="item.status" @change="struct(item)"/>
                <i class="fa fa-list-ol mr-2"></i>{{ item.label }}
              </label>
              <label v-else>
                <input disabled type="checkbox" checked/>
                <i class="fa fa-list-ol mr-2"></i>{{ item.label }}
              </label>
              <ul>
                <li class="has-child" v-for="(subItem) in item.process">
                  <input type="checkbox" checked><span class="tree-control"></span>
                  <label>
                    <input type="checkbox" v-model="subItem.status" @change="struct(subItem,item)"/>
                    {{ subItem.label }}
                  </label>

                  <ul>
                    <li class="has-child" v-for="(child) in subItem.process">
                      <input type="checkbox" checked><span class="tree-control"></span>
                      <label>
                        <input type="checkbox" v-model="child.status" @change="struct(child, subItem, item)"/>
                        {{ child.label }}
                      </label>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="$checkSubUserPermission('user_management.create-rol')">
      <b-col class="d-flex justify-content-center">
        <b-button type="button" variant="primary" @click="savePermission">SAVE INFORMATION</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {get, post} from "@/services/api";
import {COMPLETED_MODULES_PLATFORM} from "@/constants";
import {mapState} from "vuex";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: "CreatePermission",
  mixins: [show_alert_mixin],
  props: {},
  data: () => ({
    name: null,
    userId: null,
    MODULES_PLATFORM: COMPLETED_MODULES_PLATFORM(),
    selectedRol: null
  }),
  mounted() {
    this.$store.dispatch("subUserPermission/httpListPermissions", this.$store.getters.getUserId)
  },
  computed: {
    ...mapState('subUserPermission', ['arrayRoles'])
  },
  methods: {
    async clearAll() {
      this.name = null,
        this.MODULES_PLATFORM = COMPLETED_MODULES_PLATFORM(),
        this.selectedRol = null
    },
    async savePermission() {
      if (this.selectedRol !== null) {
        this.$store.dispatch("subUserPermission/httpSavePermissions", {
          rol: this.selectedRol,
          process: this.MODULES_PLATFORM,
          parent: (this.$store.getters.getRol.includes('administrator')) ? null : this.$store.getters.getUserId,
        }).then(async (data) => {
          if (data == 200) {
            this.clearAll().then()
            this.$store.commit("subUserPermission/setPermissionSelected", null)
            this.$store.dispatch("subUserPermission/httpListPermissions", this.$store.getters.getUserId)
            this.showAlertNotification("Information saved")
          } else {
            this.showAlertNotification("There is already a role configured", "error")
          }
        })
      } else {
        this.showAlertNotification("All fields are required", "error")
      }
    },
    struct(item, parent = null, upParent = null) {
      if (parent)
        parent.status = item.status ? item.status : parent.status;
      if (upParent)
        upParent.status = item.status ? item.status : upParent.status;
      if (item.process) {
        item.process.forEach(el => {
          el.status = item.status ? el.status : item.status;
          if (el.process) {
            el.process.forEach(sub => sub.status = item.status ? el.status : item.status)
          }
        })
      }
    }
  }
}
</script>
<style scoped lang="scss">

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0
}

label {
  font-weight: normal
}

/*Tree*/

.trees {
  margin-left: 10px;
}

.trees li {
  border-left: dotted 1px #bcbec0;
  padding: 1px 0 1px 25px;
  position: relative
}

.trees li > label {
  position: relative;
  left: -11px
}

.trees li:before {
  content: "";
  width: 13px;
  height: 1px;
  border-bottom: dotted 1px #bcbec0;
  position: absolute;
  top: 10px;
  left: 0
}

.trees li:last-child:after {
  content: "";
  position: absolute;
  width: 2px;
  height: 13px;
  background: #fff;
  left: -1px;
  bottom: 0px;
}

.trees li input {
  margin-right: 5px;
  margin-left: 5px
}

.trees li.has-child > ul {
  display: none
}

.trees li.has-child > input {
  opacity: 0;
  position: absolute;
  left: -14px;
  z-index: 9999;
  width: 22px;
  height: 22px;
  top: -5px
}

.trees li.has-child > input + .tree-control {
  position: absolute;
  left: -4px;
  top: 6px;
  width: 8px;
  height: 8px;
  line-height: 8px;
  z-index: 2;
  display: inline-block;
  color: #fff;
  border-radius: 3px;
}

.trees li.has-child > input + .tree-control:after {
  font-family: 'FontAwesome';
  content: "";
  font-size: 8px;
  color: #183955;
  position: absolute;
  left: 1px
}

.trees li.has-child > input:checked + .tree-control:after {
  font-family: 'FontAwesome';
  content: "";
  font-size: 8px;
  color: #183955;
  position: absolute;
  left: 1px
}

.trees li.has-child > input:checked ~ ul {
  display: block
}

.trees ul li.has-child:last-child {
  border-left: none
}

.trees ul li.has-child:nth-last-child(2):after {
  content: "";
  width: 1px;
  height: 5px;
  border-left: dotted 1px #bcbec0;
  position: absolute;
  bottom: -5px;
  left: -1px
}

.tree-alt li {
  padding: 4px 0
}
</style>

