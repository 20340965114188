<template>
  <div style="width: 100%;" v-if="$store.state.subUserPermission.permissionSelected !== null">
    <div class="tree-box box-border">
      <ul class="trees">
        <li class="has-child" v-for="(item) in $store.state.subUserPermission.permissionSelected.process">
          <input id="tree-control1" type="checkbox" checked><span
          class="tree-control"></span>
          <label v-if="item.label.toString() !=='Dashboard'">
            <input type="checkbox" v-model="item.status" @change="struct(item)"/>
            <i class="fa fa-list-ol mr-2"></i>{{ item.label }}
          </label>
          <label v-else>
            <input disabled type="checkbox" checked/>
            <i class="fa fa-list-ol mr-2"></i>{{ item.label }}
          </label>
          <ul>
            <li class="has-child" v-for="(subItem) in item.process">
              <input type="checkbox" checked><span class="tree-control"></span>
              <label>
                <input type="checkbox" v-model="subItem.status" @change="struct(subItem,item)"/>
                {{ subItem.label }}
              </label>

              <ul>
                <li class="has-child" v-for="(child) in subItem.process">
                  <input type="checkbox" checked><span class="tree-control"></span>
                  <label>
                    <input type="checkbox" v-model="child.status" @change="struct(child, subItem, item)"/>
                    {{ child.label }}
                  </label>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div style="width: 100%; display: flex; justify-content: space-around" class="text-center">
      <b-button v-if="$checkSubUserPermission('user_management.update-rol')" type="button" variant="primary"
                @click="updatePermission">UPDATE INFORMATION
      </b-button>
      <b-button v-if="$checkSubUserPermission('user_management.delete-rol')" type="button" variant="danger"
                @click="deletePermission">DELETE ROLE AND USERS RELATED
      </b-button>
    </div>
  </div>
</template>

<script>
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: "showPermission",
  mixins: [show_alert_mixin],
  methods: {
    updatePermission() {
      if (this.$store.state.subUserPermission.permissionSelected) {
        this.$store.dispatch("subUserPermission/httpUpdatePermissions",
          {
            id: this.$store.state.subUserPermission.permissionSelected._id,
            parent: this.$store.getters.getUserId,
            process: this.$store.state.subUserPermission.permissionSelected.process
          }).then(() => {
          this.$store.commit("subUserPermission/setPermissionSelected", null)
          this.showAlertNotification("Information updated")
        })
      }
    },
    deletePermission(id) {
      if (this.$store.state.subUserPermission.permissionSelected) {
        this.$swal({
          html: "Are you sure? <br> <span class='text-danger'>Users assigned to the role will be removed</span>",
          showCancelButton: true,
          confirmButtonColor: '#d14343',
          cancelButtonColor: '#000000',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.$store.dispatch("subUserPermission/httpDeletePermissions", {id: this.$store.state.subUserPermission.permissionSelected._id}).then(() => {
              this.$store.dispatch("subUserPermission/httpListPermissions", this.$store.getters.getUserId)
              this.$store.commit("subUserPermission/setPermissionSelected", null)
              this.showAlertNotification("Information deleted")
              location.reload()
            })
          }
        });
      }
    },
    struct(item, parent = null, upParent = null) {
      if (parent)
        parent.status = item.status ? item.status : parent.status;
      if (upParent)
        upParent.status = item.status ? item.status : upParent.status;
      if (item.process) {
        item.process.forEach(el => {
          el.status = item.status ? el.status : item.status;
          if (el.process) {
            el.process.forEach(sub => sub.status = item.status ? el.status : item.status)
          }
        })
      }
    }
  }

}
</script>

<style scoped lang="scss">

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0
}

label {
  font-weight: normal
}

/*Tree*/

.trees {
  margin-left: 10px;
}

.trees li {
  border-left: dotted 1px #bcbec0;
  padding: 1px 0 1px 25px;
  position: relative
}

.trees li > label {
  position: relative;
  left: -11px
}

.trees li:before {
  content: "";
  width: 13px;
  height: 1px;
  border-bottom: dotted 1px #bcbec0;
  position: absolute;
  top: 10px;
  left: 0
}

.trees li:last-child:after {
  content: "";
  position: absolute;
  width: 2px;
  height: 13px;
  background: #fff;
  left: -1px;
  bottom: 0px;
}

.trees li input {
  margin-right: 5px;
  margin-left: 5px
}

.trees li.has-child > ul {
  display: none
}

.trees li.has-child > input {
  opacity: 0;
  position: absolute;
  left: -14px;
  z-index: 9999;
  width: 22px;
  height: 22px;
  top: -5px
}

.trees li.has-child > input + .tree-control {
  position: absolute;
  left: -4px;
  top: 6px;
  width: 8px;
  height: 8px;
  line-height: 8px;
  z-index: 2;
  display: inline-block;
  color: #fff;
  border-radius: 3px;
}

.trees li.has-child > input + .tree-control:after {
  font-family: 'FontAwesome';
  content: "";
  font-size: 8px;
  color: #183955;
  position: absolute;
  left: 1px
}

.trees li.has-child > input:checked + .tree-control:after {
  font-family: 'FontAwesome';
  content: "";
  font-size: 8px;
  color: #183955;
  position: absolute;
  left: 1px
}

.trees li.has-child > input:checked ~ ul {
  display: block
}

.trees ul li.has-child:last-child {
  border-left: none
}

.trees ul li.has-child:nth-last-child(2):after {
  content: "";
  width: 1px;
  height: 5px;
  border-left: dotted 1px #bcbec0;
  position: absolute;
  bottom: -5px;
  left: -1px
}

.tree-alt li {
  padding: 4px 0
}
</style>
